export class BetsTabs {
    tabs: BetsTab[] = [
        {Id: 1, Name: 'all_bets', Active: true},
        {Id: 2, Name: 'my_bets', Active: false},
        {Id: 3, Name: 'statistics', Active: false}
    ]
}

export class BetsTab {
    Id: number;
    Name: string;
    Active: boolean;
}
