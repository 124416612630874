import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {EmitEvent} from '../models/emit-event';
import {MessageBusService} from "./message-bus.service";
import {Player} from "../models/responses/player";
import {ChatMessage} from "../models/right-content/chat/chat-message";
import {BetRequest} from "../models/requests/bet-request";
import {CashOutAndCancelRequest} from "../models/requests/cash-out-and-cancel-request";
import {DropRequest} from "../models/requests/drop-request";
import {LikeChatMessageRequest} from "../models/requests/like-chat-message-request";
import {RoomFullInfo} from "../models/game-content/room-full-info";

declare function Send(command: string, data: any): any;
declare function connect(url: string, token: string): void;

@Injectable({
    providedIn: 'root'
})
export class CommunicationManagerService {

    private isSittingState = false;
    private fatalError = false;
    private checkBalanceInterval: any;
    private checkBalanceIntervalTime = 3000;

    constructor(private messageBus: MessageBusService) {
        window['CommunicationManagerRef'] = {service: this};

        this.messageBus.on('connect', (playerInfo: Player) => {
            this.onConnect();
        });

        this.messageBus.on('pre_join_room', (playerInfo: RoomFullInfo) => {
            this.onPreJoinRoom();
        });

        this.messageBus.on('join_room', (playerInfo: Player) => {
            this.onJoinRoom();
        });

        this.messageBus.on('chat_send', (message: ChatMessage) => {
            this.chatSend(message);
        });

        this.messageBus.on('change_avatar', (message: any) => {
            this.avatarChange(message);
        });

        this.messageBus.on('drop_request', (data: DropRequest) => {
            this.dropRequestCall(data);
        });

        this.messageBus.on('message_like_request', (data: LikeChatMessageRequest) => {
           this.messageLikeRequest(data);
        });

        setTimeout(() => {
            connect(environment.socketUrl, this.getUrlVariableWithVariableName(environment.token));
        }, 500);
    }

    messageReceived(command: string, data: any): void {
        this.messageBus.emit(new EmitEvent(command, data));
    }

    onConnect(): void {
        const data: any = {};
        data.room_id = 2;
        Send('diri_pre_join_room', data);
    }

    onPreJoinRoom(): void {
        const data: any = {};
        data.room_id = 2;
        Send('diri_join_room', data);
    }

    onJoinRoom(): void {
        this.isSittingState = true;
        this.checkBalance();
    }

    avatarChange(message: any): void {
        Send('diri_change_avatar', message);
    }

    chatSend(message: ChatMessage): void {
       Send('diri_chat_message', message);
    }

    dropRequestCall(data: DropRequest): void {
        Send('diri_drop', data);
    }

    messageLikeRequest(data: LikeChatMessageRequest): void {
        Send('diri_message_like', data);
    }

    checkBalance(): void {
        this.checkBalanceInterval = setInterval(() => {
            Send('balance_check', {});
        }, this.checkBalanceIntervalTime);
    }

    getUrlVariableWithVariableName(variable: string) {
        const query = window.location.search.substring(1);
        const vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split("=");
            if (pair[0] === variable) {
                return pair[1];
            }
        }

        return '';
    }

    betRequest(betRequest: BetRequest): void {
        Send('diri_bet', betRequest);
    }

    cashOutAndCancelRequest(request: CashOutAndCancelRequest): void {
        Send('diri_cash_out', request);
    }

    clearCheckBalanceInterval(): void {
        clearInterval(this.checkBalanceInterval);
    }


}
