export enum LanguagesEnum {
    English = 1,
    Georgian,
    Russian,
    Turkish,
    Persian,
    Italian,
    Ukrainian,
    Portuguese,
    Spanish,
    Korean,
    French,
    Armenian,
    Arabic,
    Chinese,
    Azeri,
    Brazil,
    Hindi
}
