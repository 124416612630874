import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Player} from "../../../../core/models/responses/player";
import {MessageBusService} from "../../../../core/services/message-bus.service";
import {EmitEvent} from "../../../../core/models/emit-event";
import {DropRequest} from "../../../../core/models/requests/drop-request";
import {DropForm} from "../../../../core/models/right-content/chat/drop/drop-form";
import {DropInfo} from "../../../../core/models/right-content/chat/drop/drop-info";
import {Drop} from "../../../../core/models/right-content/chat/drop/drop";

declare var $: any;

@Component({
    selector: 'app-drop',
    templateUrl: './drop.component.html',
    styleUrls: ['./drop.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropComponent implements OnInit {

    @Input()
    player: Player;

    dropEnable: boolean = false;

    dropForm: DropForm = new DropForm();

    dropData: Drop = new Drop();

    constructor(private messageBus: MessageBusService, private cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.messageBus.on('drop_info', (data: DropInfo) => {
            this.addDropInfo(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('drop', (data: Drop) => {
            this.getDropData(data);
            this.cd.detectChanges();
        });
    }

    addDropInfo(data: DropInfo): void {
        this.dropForm.min_amount = data.min_amount;
        this.dropForm.max_amount = data.max_amount;
        this.dropForm.min_player_count = data.min_player_count;
        this.dropForm.max_player_count = data.max_player_count;

        if (this.dropForm.amount < data.min_amount) {
            this.dropForm.amount = data.min_amount;
        }
        if (this.dropForm.amount > data.max_amount) {
            this.dropForm.amount = data.max_amount;
        }

        if (this.dropForm.players_count < data.min_player_count) {
            this.dropForm.players_count = data.min_player_count;
        }
        if (this.dropForm.players_count > data.max_player_count) {
            this.dropForm.players_count = data.max_player_count;
        }

        this.dropEnable = data.is_enabled;
    }

    increaseAmount(status: number): void {

        this.dropForm.amount += (status === 1) ? -0.1 : 0.1;

        if (this.dropForm.amount < this.dropForm.min_amount) {
            this.dropForm.amount = this.dropForm.min_amount;
        }

        if (this.dropForm.amount > this.dropForm.max_amount) {
            this.dropForm.amount = this.dropForm.max_amount;
        }

        this.dropForm.amount = Number((this.dropForm.amount).toFixed(2));
    }

    updateAmount(event): void {

        let newValue = Number(event.target.value);

        if (newValue < this.dropForm.min_amount) {
            newValue = this.dropForm.min_amount;
        }

        if (newValue > this.dropForm.max_amount) {
            newValue = this.dropForm.max_amount;
        }

        this.dropForm.amount = newValue;
    }

    updatePlayersCount(event): void {

        let newValue = Number(event.target.value);

        if (newValue < this.dropForm.min_player_count) {
            newValue = this.dropForm.min_player_count;
        }

        if (newValue > this.dropForm.max_player_count) {
            newValue = this.dropForm.max_player_count;
        }

        this.dropForm.players_count = newValue;
    }

    increasePlayersCount(status: number): void {

        this.dropForm.players_count += (status === 1) ? -1 : 1;

        if (this.dropForm.players_count < this.dropForm.min_player_count) {
            this.dropForm.players_count = this.dropForm.min_player_count;
        }

        if (this.dropForm.players_count > this.dropForm.max_player_count) {
            this.dropForm.players_count = this.dropForm.max_player_count;
        }
    }

    dropRequest(): void {
        this.dropForm.requestDisabled = true;
        const that = this;
        setTimeout(() => {
            that.dropForm.requestDisabled = false;
            this.cd.detectChanges();
        }, 4000);

        const dropRequest = new DropRequest();
        dropRequest.amount = this.dropForm.amount;
        dropRequest.player_count = this.dropForm.players_count;
        this.messageBus.emit(new EmitEvent('drop_request', dropRequest));
    }

    getDropData(data: Drop): void {
        if (data.sender.player_id === this.player.playerId) {
            this.dropForm.requestSuccessfullySended = true;
            const that = this;
            setTimeout(() => {
                that.dropForm.requestSuccessfullySended = false;
                this.cd.detectChanges();
            }, 4000);
        }
    }


    closeDropDownMenu(): void {
        document.getElementById("chat__drop-button").click();
    }


}

