import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'customDatetime'
})
export class CustomDatetimePipe implements PipeTransform {

  transform(value: any, format?: string): any {

    if (!value) {
      return '';
    }

    if (!format) {
      format = 'DD-MM-YYYY HH:mm:ss';
    }

    return  moment.utc(value).local().format(format);
  }

}
