import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BetsTabs} from "../../core/models/left-content/bets-tabs";
import {MessageBusService} from "../../core/services/message-bus.service";
import {LeaderBoard, LeaderBoardPlayer} from "../../core/models/right-content/leader-board";
import {Bet} from "../../core/models/left-content/bet";
import {PlayerBetHistory} from "../../core/models/right-content/player-bet-history";
import {Player} from "../../core/models/responses/player";
import {Round} from "../../core/models/right-content/round";
import {Drop} from "../../core/models/right-content/chat/drop/drop";
import {Avatar} from "../../core/models/responses/avatar";

declare var $: any;

@Component({
    selector: 'app-left-content',
    templateUrl: './left-content.component.html',
    styleUrls: ['./left-content.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftContentComponent implements OnInit {

    @Input()
    player: Player;

    @Input()
    activeMobileMenuItem: number;

    betsTabs = new BetsTabs();

    bets: Bet[] = [];

    myBets: LeaderBoardPlayer[] = [];

    leaderBoard: LeaderBoard;

    dropData: Drop = new Drop();

    constructor(private cd: ChangeDetectorRef, private messageBus: MessageBusService) {
    }

    ngOnInit() {

        this.messageBus.on('leader_board', (data: LeaderBoard) => {
            this.addLeaderBoardPlayers(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('player_bet_history', (data: PlayerBetHistory) => {
            this.addMyBets(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('bet', (data: Bet) => {
            this.addBet(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('cash_out', (data: Bet) => {
            this.cashOutBet(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('cancel_bet', (data: Bet) => {
            this.cancelBet(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('clear_bets', () => {
            this.bets.length = 0;
            this.cd.detectChanges();
        });

        this.messageBus.on('add_my_bet', (data: Bet) => {
            this.addMyBet(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('remove_my_bet', (data: Bet) => {
            this.removeMyBet(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('update_my_bet_after_cashout', (data: Bet) => {
            this.updateMyBetAfterCashOut(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('active_round', (data: Round) => {
            data.bets.forEach(b => {
                this.addBet(b);
            });
        });

        this.messageBus.on('drop', (data: Drop) => {
            this.getDropData(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('change_avatar_response', (data: Avatar) => {
            this.changeAvatarResponse(data);
            this.cd.detectChanges();
        });
    }

    changeAvatarResponse(data: Avatar): void {
        this.bets.forEach(bet => {
            if (bet.playerId === data.player_id) {
                bet.avatar_id = data.avatar_id;
            }
        });

        this.leaderBoard.leaderBoardPlayers.forEach(player => {
            if (player.playerId === data.player_id) {
                player.avatar_id = data.avatar_id;
            }
        });
    }


    getDropData(data: Drop): void {
        data.receivers.forEach(r => {
            if (r.player_id === this.player.playerId) {
                this.dropData = data;
                $('#ZepPopup').modal();
            }
        });
    }

    addLeaderBoardPlayers(data: LeaderBoard): void {

        if (this.player != undefined) {
            data.leaderBoardPlayers.forEach(p => {
                if (p.playerId === this.player.playerId) {
                    p.username = this.player.username;
                }
            });
        }
        this.leaderBoard = data;
    }

    addMyBet(data: Bet): void {
        const myBet = new LeaderBoardPlayer();
        myBet.ActionId = data.ActionId;
        myBet.username = data.username;
        myBet.betAmount = data.bet;
        myBet.multiplier = data.cashoutMultiplier;
        myBet.winAmount = data.winAmount;
        this.myBets.unshift(myBet);
    }

    removeMyBet(data: Bet): void {
        this.myBets.forEach((b, i) => {
            if (b.ActionId === data.ActionId) {
                this.myBets.splice(i, 1);
            }
        });
    }

    updateMyBetAfterCashOut(data: Bet): void {
        this.myBets.forEach(b => {
            if (b.ActionId === data.ActionId) {
                b.multiplier = data.cashoutMultiplier;
                b.winAmount = data.winAmount;
            }
        });
    }

    addMyBets(data: PlayerBetHistory): void {
        this.myBets = data.Data;
    }

    changeBetsTabs(tabId: number): void {
        this.betsTabs.tabs.forEach(tab => {
            tab.Active = (tabId === tab.Id);
        });
    }

    addBet(bet: Bet): void {
        if (bet.playerId === this.player.playerId) {
            bet.username = this.player.username;
        }
        this.bets.push(bet);
        this.sortBets();
    }

    sortBets() {
        this.bets.forEach(b => {
            b.sortIndex = 0;
            if (b.playerId === this.player.playerId) {
                b.sortIndex += 1000000;
            }

            if ((b.Active || b.winAmount>0) && !(b.playerId === this.player.playerId)) {
                b.sortIndex -= 1000000;
            }

            b.sortIndex += b.bet;
        });

        this.bets.sort((a, b) => a.sortIndex - b.sortIndex);
    }

    cashOutBet(bet: Bet): void {
        this.bets.forEach(b => {
            if (b.ActionId === bet.ActionId) {
                b.Active = true;
                b.cashoutMultiplier = bet.cashoutMultiplier;
                b.winAmount = bet.winAmount;
            }
        });

        this.sortBets();
    }

    cancelBet(bet: Bet): void {
        this.bets.forEach((b, i) => {
            if (b.ActionId === bet.ActionId) {
                this.bets.splice(i, 1);
            }
        });
    }


}
