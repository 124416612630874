import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'hideUsername'
})
export class HideUsernamePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        return value.substring(0, 1) + '.....' + value.charAt(value.length - 1);
    }

}
