export enum SoundNames {
    main_sound,
    button_click,
    explosion,
    start,
    win,
    collect,
    he_win,
    o2_win
}
