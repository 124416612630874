import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Player} from "../../../core/models/responses/player";
import {MessageBusService} from "../../../core/services/message-bus.service";
import {JackpotInfo} from "../../../core/models/responses/jackpot-info";
import {JackpotWin} from "../../../core/models/responses/jackpot-win";
import {JackpotType} from "../../../core/models/header/jackpots/jackpot-type.enum";
import {JackpotPlay} from "../../../core/models/responses/jackpot-play";
import {TranslateServiceService} from "../../../core/services/translate-service.service";
import {SoundsService} from "../../../core/services/sounds.service";
import {SoundNames} from "../../../core/models/sound-names.enum";
import {JackpotInfoModalData} from "../../../core/models/header/jackpots/jackpot-info-modal-data";
import {JackpotHistory} from "../../../core/models/header/jackpots/jackpot-history";

declare var $: any;

@Component({
    selector: 'app-jackpots',
    templateUrl: './jackpots.component.html',
    styleUrls: ['./jackpots.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JackpotsComponent implements OnInit {

    @Input()
    player: Player;

    jackpotInfo: JackpotInfo;

    jackpotInfoModalData: JackpotInfoModalData = new JackpotInfoModalData();

    constructor(private cd: ChangeDetectorRef, private translateServiceService: TranslateServiceService, private messageBus: MessageBusService, private soundService: SoundsService) {
    }

    ngOnInit() {
        this.messageBus.on('jackpot_info', (data: JackpotInfo) => {
            this.addJackpotInfo(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('jackpot_win', (data: JackpotWin) => {
            this.addJackpotWin(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('jackpot_play', (data: JackpotPlay) => {
            this.addJackpotPlay(data);
            this.cd.detectChanges();
        });

    }

    addJackpotInfo(data: JackpotInfo): void {
        this.jackpotInfo = data;
        this.jackpotInfo.small_jackpot_array = this.generateNumberToDigitsArray(this.jackpotInfo.small_jackpot);
        this.jackpotInfo.big_jackpot_array = this.generateNumberToDigitsArray(this.jackpotInfo.big_jackpot);
    }

    generateNumberToDigitsArray(value: number): number[] {

        value = Math.floor(value);

        const output = [],
            sNumber = value.toString();

        for (let i = 0, len = sNumber.length; i < len; i += 1) {
            output.push(+sNumber.charAt(i));
        }

        const outputLength = output.length;

        if (outputLength < 6) {
            for (let i = 0; i < (6-outputLength); i ++) {
                output.unshift(0);
            }
        }

        return output;
    }

    addJackpotWin(data: JackpotWin): void {

        if (data.jackpot_type === JackpotType.small) {

            this.soundService.playSound(SoundNames.o2_win);

            this.jackpotInfoModalData.small_jackpot_modal_array = this.generateNumberToDigitsArray(data.win_amount);

            $('#jackpot-modal-oxygen').modal();

        } else if (data.jackpot_type === JackpotType.big) {

            this.soundService.playSound(SoundNames.he_win);

            this.jackpotInfoModalData.big_jackpot_modal_array = this.generateNumberToDigitsArray(data.win_amount);

            $('#jackpot-modal-helium').modal();
        }
    }

    addJackpotPlay(data: JackpotPlay): void {

        const self = this;
        const reductionInterval = 20;
        const reductionSpeed = 100;

        if (data.jackpot_type === JackpotType.small) {

            const cdInterval =  setInterval(function() {
                // self.soundService.playSound(SoundNames.collect);
                self.jackpotInfo.small_jackpot_array = self.generateNumberToDigitsArray(self.jackpotInfo.small_jackpot);
                self.jackpotInfo.small_jackpot -= reductionInterval;

                if (self.jackpotInfo.small_jackpot <= 0) {
                    self.jackpotInfo.small_jackpot_array = self.generateNumberToDigitsArray(0);
                    clearInterval(cdInterval);
                }
                self.cd.detectChanges();
            }, reductionSpeed);

        }else if (data.jackpot_type === JackpotType.big) {

            const cdInterval =  setInterval(function() {
                // self.soundService.playSound(SoundNames.collect);
                self.jackpotInfo.big_jackpot_array = self.generateNumberToDigitsArray(self.jackpotInfo.big_jackpot);
                self.jackpotInfo.big_jackpot -= reductionInterval;

                if (self.jackpotInfo.big_jackpot <= 0) {
                    self.jackpotInfo.big_jackpot_array = self.generateNumberToDigitsArray(0);
                    clearInterval(cdInterval);
                }
                self.cd.detectChanges();
            }, reductionSpeed);

        }
    }


}
