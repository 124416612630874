export class BetRequestForm {
    betAmount: number = 1;
    betButtonActionType: number = BetButtonActionType.bet;
    betButtonDisabled: boolean = false;
    betActionSended: boolean = false;
    autoBet: boolean = false;
    autoCashOut: boolean = false;
    cashoutMultiplier: number = 5.00;

    freeBetMinCashOutMultiplier: number = 0;
    WinAmount: number = 0;
    cashoutDisabled: boolean;
}

export enum BetButtonActionType {
    bet = 1,
    betForNextRound,
    cashout,
    cancel
}
