import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FreeBet, FreeBets} from "../../../core/models/header/free-bets";
import {MessageBusService} from "../../../core/services/message-bus.service";
import {GameContentService} from "../../../core/services/game-content.service";
import {EmitEvent} from "../../../core/models/emit-event";
import {Bet} from "../../../core/models/left-content/bet";
import {Player} from "../../../core/models/responses/player";

declare var $: any;

@Component({
    selector: 'app-free-bets',
    templateUrl: './free-bets.component.html',
    styleUrls: ['./free-bets.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreeBetsComponent implements OnInit {

    @Input()
    player: Player;

    freeBets: FreeBets = new FreeBets();

    activeFreeBet: FreeBet;

    constructor(private cd: ChangeDetectorRef, private messageBus: MessageBusService) {
    }

    ngOnInit() {
        this.messageBus.on('free_bets', (data: FreeBets) => {
            this.addFreeBets(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('bet', (bet: Bet) => {
            if (bet.free_bet_id > 0) {
                this.freeBets.Data.forEach((freeBet, i) => {
                    if (bet.free_bet_id === freeBet.free_bet_id) {
                        this.freeBets.Data.splice(i, 1);
                    }
                });
            }
            this.cd.detectChanges();
        });
    }

    addFreeBets(data: FreeBets): void {
        this.freeBets = data;
    }

    freeBetRequestModalOpen(freeBet: FreeBet): void {

        this.activeFreeBet = freeBet;

        $('#free-bet-request-modal').modal();
    }

    freeBetRequest(freeBet: FreeBet): void {
        this.messageBus.emit(new EmitEvent('free_bet_request', freeBet));

        $('#free-bet-request-modal').modal('hide');
        $('#FreeBets').modal('hide');
    }


}
