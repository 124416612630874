export class LeaderBoard {
    currencySymbol: string;
    leaderBoardPlayers: LeaderBoardPlayer[];
}

export class LeaderBoardPlayer {
    playerId: number;
    username: string;
    multiplier: number;
    maxMultiplier: number;
    winAmount: number;
    betAmount: number;
    avatar_id: number;
    /* Custom */
    ActionId: number;
}
