export class FreeBets {
    Data: FreeBet[] = [];
}

export class FreeBet {
    free_bet_id: number;
    player_id: number;
    bet_amount: number;
    min_cashout_multiplier: number;
    is_active: boolean;
    finish_time: string;
}
