export class Drop {
    sender: DropSender;
    receivers: DropResevers[];
    amount: number;
}

export class DropSender {
    player_id: number;
    avatar_id: number;
    username: string;
    bet: number;
    win: number;
}

export class  DropResevers {
    player_id: number;
    avatar_id: number;
    username: string;
    bet: number;
    win: number;
}
