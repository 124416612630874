import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Rounds} from "../../../core/models/responses/rounds";
import {MessageBusService} from "../../../core/services/message-bus.service";
import {Round} from "../../../core/models/right-content/round";
import {RightContentService} from "../../../core/services/right-content.service";
import {JackpotHistory, JackpotHistoryRound} from "../../../core/models/header/jackpots/jackpot-history";
import { JackpotInfo } from 'src/app/core/models/responses/jackpot-info';

declare var $: any;

@Component({
    selector: 'app-rounds-history',
    templateUrl: './rounds-history.component.html',
    styleUrls: ['./rounds-history.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoundsHistoryComponent implements OnInit {

    rounds: Rounds;

    activeRound: Round;

    historyModalTab: number = 1;

    jackpotHistory: JackpotHistory = new JackpotHistory();

    jackpotInfo: JackpotInfo;

    // detailJackpotHistoryRow: JackpotHistoryRound;

    constructor(private cd: ChangeDetectorRef, private messageBus: MessageBusService, private rightContentService: RightContentService) {
    }

    ngOnInit() {
        this.messageBus.on('rounds', (rounds: Rounds) => {
            this.rounds = rounds;
            this.cd.detectChanges();
        });

        this.messageBus.on('round_finish', (round: Round) => {
            this.rounds.Rounds.forEach(r => {
                if (r.roundId === round.roundId) {
                    r.multiplier = round.multiplier;
                    r.key = round.key;
                    r.result = round.result;
                    r.isFinished = round.isFinished;
                    r.jackpotType = round.jackpotType;
                }
            });
            if (round.isTop) {
                this.rounds.TopRounds.push(round);
            }
            this.cd.detectChanges();
        });

        this.messageBus.on('round_create', (round: Round) => {
            if (this.rounds) {
                this.rounds.Rounds.push(round);
                this.cd.detectChanges();
            }
        });

        this.messageBus.on('jackpot_history', (data: JackpotHistory) => {
            this.addJackpotHistory(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('jackpot_history_row', (data: JackpotHistoryRound) => {
            this.addJackpotHistoryRow(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('jackpot_info', (data: JackpotInfo) => {
            this.addJackpotInfo(data);
            this.cd.detectChanges();
        });
    }

    addJackpotInfo(data: JackpotInfo): void {
        this.jackpotInfo = data;
    }

    // openRoundDetailModelFromGameContent(roundId: number): void {
    //     this.rightContentService.openRoundDetailModel(roundId);
    // }

    openRoundDetailModelFromGameContent(roundId: number): void {
        this.rounds.Rounds.forEach(r => {
            if (r.roundId === roundId) {
                this.activeRound = r;
            }
        });
        $('#justice-modal').modal();
    }

    addJackpotHistory(data: JackpotHistory): void {
        this.jackpotHistory = data;
    }

    openJackpotRoundDetailModel(roundId: number): void {
        this.jackpotHistory.Rounds.forEach(r => {
            if (r.round_id === roundId) {
                r.detailViewData = (!r.detailViewData) ? r : null;
            }
        });
    }

    addJackpotHistoryRow(round: JackpotHistoryRound): void {

        this.jackpotHistory.Rounds.push(round);

        if (this.jackpotHistory.Rounds.length > this.jackpotHistory.MaxCount) {
            this.jackpotHistory.Rounds.shift();
        }
    }



}

