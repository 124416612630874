export class RoundsTabs {
    tabs: RoundsTab[] = [
        {Id: 1, Name: 'history', Active: true},
        {Id: 2, Name: 'top', Active: false}
    ]
}

export class RoundsTab {
    Id: number;
    Name: string;
    Active: boolean;
}
