import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {ResizedEvent} from "angular-resize-event";
import {MessageBusService} from "../../core/services/message-bus.service";
import {IncreaseBet} from "../../core/models/responses/increase-bet";
import {BetRequest} from "../../core/models/requests/bet-request";
import {CommunicationManagerService} from "../../core/services/communication-manager.service";
import {BetButtonActionType, BetRequestForm} from "../../core/models/game-content/bet-request-form";
import {TimerResponseData} from "../../core/models/responses/timer-response-data";
import {GameStatus} from "../../core/models/game-content/game-status.enum";
import {BetRestriction} from "../../core/models/game-content/bet-restriction";
import {RoomInfo} from "../../core/models/game-content/room-info";
import {RoomFullInfo} from "../../core/models/game-content/room-full-info";
import {Bet} from "../../core/models/left-content/bet";
import {Player} from "../../core/models/responses/player";
import {CashOutAndCancelRequest} from "../../core/models/requests/cash-out-and-cancel-request";
import {BetActionType} from "../../core/models/game-content/bet-action-type.enum";
import {EmitEvent} from "../../core/models/emit-event";
import {WinPopup, WinPopups} from "../../core/models/game-content/win-popup";
import {GameCurrentState} from "../../core/models/responses/game-current-state";
import {BetValue} from "../../core/models/game-content/bet-value";
import {SoundsService} from "../../core/services/sounds.service";
import {SoundNames} from "../../core/models/sound-names.enum";
import {ErrorModel} from "../../core/models/responses/error-model";
import {UiService} from 'src/app/core/services/ui.service';
import {FreeBet} from "../../core/models/header/free-bets";

declare var $: any;

@Component({
    selector: 'app-game-content',
    templateUrl: './game-content.component.html',
    styleUrls: ['./game-content.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GameContentComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('myIdentifier')
    myIdentifier: ElementRef;

    @Input()
    player: Player;

    @Input()
    activeMobileMenuItem: number;

    roomInfo = new RoomInfo();

    gameStatus = GameStatus.Betting;

    loaderLeftTimeSize = 10;
    loaderTotalTimeSize = 10;
    loaderTimeDelta = 0.1;
    gamePanelCount = 1;
    betValue: BetValue = { Value: 0, ColorRed: false };

    betRequestFormFirst: BetRequestForm = new BetRequestForm();

    betRequestFormSecond: BetRequestForm = new BetRequestForm();

    betRestriction: BetRestriction = new BetRestriction();

    savedBetRequests: BetRequest[] = [];

    winPopups: WinPopups = new WinPopups();

    isMobile = false;

    constructor(private cd: ChangeDetectorRef, private messageBus: MessageBusService, private commService: CommunicationManagerService,
                private soundService: SoundsService, private uiService: UiService) {
    }

    ngOnInit() {

        this.messageBus.on('pre_join_room', (data: RoomFullInfo) => {
            this.updateRoomInfo(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('timer', (timerData: TimerResponseData) => {
            this.timerFromMessageBus(timerData);
            this.cd.detectChanges();
        });

        this.messageBus.on('increase_bet', (data: IncreaseBet) => {
            this.increaseBetFromMessageBus(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('bet', (data: Bet) => {

            if (data.playerId === this.player.playerId) {
                if (data.index === 0) {
                    this.betRequestFormFirst.betButtonActionType = BetButtonActionType.cancel;
                    this.betRequestFormFirst.betActionSended = false;
                    this.betRequestFormFirst.betButtonDisabled = false;
                    if (data.is_free_bet) {
                        this.betRequestFormFirst.betAmount = data.bet;
                        this.betRequestFormFirst.freeBetMinCashOutMultiplier = data.min_cashout_multiplier;
                    }
                } else {
                    this.betRequestFormSecond.betButtonActionType = BetButtonActionType.cancel;
                    this.betRequestFormSecond.betActionSended = false;
                    this.betRequestFormSecond.betButtonDisabled = false;
                }

                this.messageBus.emit(new EmitEvent('add_my_bet', data));
            }
            this.cd.detectChanges();
        });

        this.messageBus.on('bet_error', (data: ErrorModel) => {
            if (data.index === 0) {

                // if (this.betRequestFormFirst.freeBetMinCashOutMultiplier > 0) {
                //     this.betRequestFormFirst.freeBetMinCashOutMultiplier = 0;
                // }

                this.betRequestFormFirst.betActionSended = false;
            } else {
                this.betRequestFormSecond.betActionSended = false;
            }
            this.cd.detectChanges();
        });

        this.messageBus.on('cash_out', (data: Bet) => {
            this.cashOutFromMessageBus(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('cancel_bet', (data: Bet) => {
            if (data.playerId === this.player.playerId) {
                if (data.index === 0) {
                    this.betRequestFormFirst.betButtonActionType = BetButtonActionType.bet;
                    this.betRequestFormFirst.betActionSended = false;
                } else {
                    this.betRequestFormSecond.betButtonActionType = BetButtonActionType.bet;
                    this.betRequestFormSecond.betActionSended = false;
                }

                this.messageBus.emit(new EmitEvent('remove_my_bet', data));
            }
            this.cd.detectChanges();
        });

        this.messageBus.on('game_current_state', (data: GameCurrentState) => {
            this.addGameCurrentState(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('reset_bet_from_error', (data: ErrorModel) => {
            this.resetBetFromErrorMessageBus(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('free_bet_request', (freeBet: FreeBet) => {
            this.betRequestForFreeBet(freeBet);
            this.cd.detectChanges();
        });

        this.uiService.isMobile.subscribe(isMobile => {
            this.isMobile = isMobile;
            this.cd.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.uiService.isMobile.unsubscribe();
        this.cd.detectChanges();
    }

    addGameCurrentState(data: GameCurrentState): void {

        this.gameStatus = data.current_state;

        if (data.current_state === GameStatus.Betting) {
            if (data.timer_data.is_Active) {
                this.startGameStateLoaderAnimation(data.timer_data.time_left, data.timer_data.time);
            }
        }

        if (data.current_state === GameStatus.MultiplierIncrease) {
            this.betRequestFormFirst.betButtonActionType = BetButtonActionType.betForNextRound;
            this.betRequestFormSecond.betButtonActionType = BetButtonActionType.betForNextRound;
        }

        /* Add active bets */
        data.Data.forEach((bet, i) => {
            if (bet !== null) {
                if (i === 0) {
                    this.betRequestFormFirst.betAmount = bet.bet;
                    this.betRequestFormFirst.autoCashOut = bet.autoCashOut;
                    if (bet.autoCashOut) {
                        this.betRequestFormFirst.cashoutMultiplier = bet.cashoutMultiplier;
                    }
                    // this.betRequestFormFirst.betButtonActionType = BetButtonActionType.cashout;
                    if (data.current_state === GameStatus.Betting) {
                        this.betRequestFormFirst.betButtonActionType = BetButtonActionType.cancel;
                    }else if (data.current_state === GameStatus.MultiplierIncrease){
                        this.betRequestFormFirst.betButtonActionType = BetButtonActionType.cashout;
                    }

                    if (bet.is_free_bet && (this.betValue.Value < bet.min_cashout_multiplier)) {
                        this.betRequestFormFirst.freeBetMinCashOutMultiplier = bet.min_cashout_multiplier;
                    }
                }
                if (i === 1) {
                    this.gamePanelCount = 2;
                    this.betRequestFormSecond.betAmount = bet.bet;
                    this.betRequestFormSecond.autoCashOut = bet.autoCashOut;
                    if (bet.autoCashOut) {
                        this.betRequestFormSecond.cashoutMultiplier = bet.cashoutMultiplier;
                    }
                    // this.betRequestFormSecond.betButtonActionType = BetButtonActionType.cashout;
                    if (data.current_state === GameStatus.Betting) {
                        this.betRequestFormSecond.betButtonActionType = BetButtonActionType.cancel;
                    }else if (data.current_state === GameStatus.MultiplierIncrease) {
                        this.betRequestFormSecond.betButtonActionType = BetButtonActionType.cashout;
                    }
                }
            }
        });
    }

    timerFromMessageBus(timerData: TimerResponseData): void {

        this.gameStatus = timerData.current_state;

        if (timerData.current_state === GameStatus.Betting) {

            this.messageBus.emit(new EmitEvent('clear_bets'));
            this.startGameStateLoaderAnimation(timerData.time, timerData.time);
            this.winPopups.Items.length = 0;
            this.winPopups.TwoItem = false;
            this.betValue.ColorRed = false;

            this.betRequestFormFirst.freeBetMinCashOutMultiplier = 0;

            if(!this.betRequestFormFirst.autoBet && !this.savedBetRequests.find(r => r.index === 0)){
                this.betRequestFormFirst.betButtonDisabled = false;
            }

            if(!this.betRequestFormSecond.autoBet && !this.savedBetRequests.find(r => r.index === 1)){
                this.betRequestFormSecond.betButtonDisabled = false;
            }

            if (this.betRequestFormFirst.betButtonActionType != BetButtonActionType.cancel) {
                this.betRequestFormFirst.betButtonActionType = BetButtonActionType.bet;
                this.betRequestFormFirst.betButtonDisabled = false;

                if(this.betRequestFormFirst.autoBet){
                    this.betRequestFormFirst.betButtonDisabled = true;
                }
            }
            if (this.betRequestFormSecond.betButtonActionType != BetButtonActionType.cancel) {
                this.betRequestFormSecond.betButtonActionType = BetButtonActionType.bet;
                this.betRequestFormSecond.betButtonDisabled = false;

                if(this.betRequestFormSecond.autoBet){
                    this.betRequestFormSecond.betButtonDisabled = true;
                }
            }

            if (this.betRequestFormFirst.autoBet && !this.savedBetRequests.find(r => r.index === 0)) {
                const betRequest: BetRequest = {
                    index: 0,
                    bet: this.betRequestFormFirst.betAmount,
                    autoCashOut: this.betRequestFormFirst.autoCashOut,
                    cashOutMultiplier: this.betRequestFormFirst.cashoutMultiplier,

                    is_free_bet: false,
                    free_bet_id: 0,
                    free_bet_cash_out_multiplier: 0,
                    autoBet: true
                };

                this.commService.betRequest(betRequest);
            }
            if (this.betRequestFormSecond.autoBet && !this.savedBetRequests.find(r => r.index === 1)) {
                const betRequest: BetRequest = {
                    index: 1,
                    bet: this.betRequestFormSecond.betAmount,
                    autoCashOut: this.betRequestFormSecond.autoCashOut,
                    cashOutMultiplier: this.betRequestFormSecond.cashoutMultiplier,

                    is_free_bet: false,
                    free_bet_id: 0,
                    free_bet_cash_out_multiplier: 0,
                    autoBet: true
                };
                this.commService.betRequest(betRequest);
            }

            this.savedBetRequests.forEach(betRequest => {
                if (betRequest.free_bet_cash_out_multiplier > 0) {
                    this.betRequestFormFirst.freeBetMinCashOutMultiplier = betRequest.free_bet_cash_out_multiplier;
                    this.betRequestFormFirst.betAmount = betRequest.bet;
                }
                this.commService.betRequest(betRequest);
            });
            this.savedBetRequests.length = 0;
        }

        if (timerData.current_state === GameStatus.Bust) {
            this.soundService.playSound(SoundNames.explosion);
            this.betValue.ColorRed = true;
            this.betRequestFormFirst.betButtonDisabled = true;
            this.betRequestFormSecond.betButtonDisabled = true;
            if (this.betRequestFormFirst.betButtonActionType !== BetButtonActionType.cancel) {
                this.betRequestFormFirst.betButtonActionType = BetButtonActionType.bet;
            }
            if (this.betRequestFormSecond.betButtonActionType !== BetButtonActionType.cancel) {
                this.betRequestFormSecond.betButtonActionType = BetButtonActionType.bet;
            }
        }
    }

    increaseBetFromMessageBus(data: IncreaseBet): void {

        this.betValue.Value = data.Result;
        
        const firstBetAmount = +(this.betRequestFormFirst.betAmount * this.betValue.Value).toFixed(2);
        const secondBetAmount = +(this.betRequestFormSecond.betAmount * this.betValue.Value).toFixed(2);

        $('#win-amount-1').text(firstBetAmount);
        $('#win-amount-2').text(secondBetAmount);

        if (this.betValue.Value >= this.betRequestFormFirst.freeBetMinCashOutMultiplier) {
            this.betRequestFormFirst.freeBetMinCashOutMultiplier = 0;
        }

        if (this.gameStatus != GameStatus.MultiplierIncrease) {
            this.gameStatus = GameStatus.MultiplierIncrease;

            this.soundService.playSound(SoundNames.start);

            this.betRequestFormFirst.betButtonDisabled = false;
            this.betRequestFormSecond.betButtonDisabled = false;

            if (this.betRequestFormFirst.betButtonActionType === BetButtonActionType.bet) {
                this.betRequestFormFirst.betButtonActionType = BetButtonActionType.betForNextRound;
            }
            if (this.betRequestFormFirst.betButtonActionType === BetButtonActionType.cancel) {
                this.betRequestFormFirst.betButtonActionType = BetButtonActionType.cashout;
            }
            if (this.betRequestFormSecond.betButtonActionType === BetButtonActionType.bet) {
                this.betRequestFormSecond.betButtonActionType = BetButtonActionType.betForNextRound;
            }
            if (this.betRequestFormSecond.betButtonActionType === BetButtonActionType.cancel) {
                this.betRequestFormSecond.betButtonActionType = BetButtonActionType.cashout;
            }
        }
    }

    cashOutFromMessageBus(data: Bet): void {

        if (data.playerId === this.player.playerId) {

            this.betRequestFormFirst.cashoutDisabled = false;
            this.betRequestFormSecond.cashoutDisabled = false;

            this.soundService.playSound(SoundNames.win);

            if (data.index === 0) {
                this.betRequestFormFirst.betButtonActionType = BetButtonActionType.betForNextRound;
            } else {
                this.betRequestFormSecond.betButtonActionType = BetButtonActionType.betForNextRound;
            }

            const popup = new WinPopup();
            popup.Multiplier = data.cashoutMultiplier;
            popup.Win = data.winAmount;
            popup.Index = data.index;
            this.winPopups.Items.push(popup);
            setTimeout(() => {
                this.winPopups.Items.forEach((p, i) => {
                    if (p.Index === data.index) {
                        this.winPopups.Items.splice(i, 1);
                        if (this.winPopups.Items.length < 2) {
                            this.winPopups.TwoItem = false;
                        }
                    }
                });
                this.cd.detectChanges();
            }, 3500);
            if (this.winPopups.Items.length === 2) {
                this.winPopups.TwoItem = true;
            }

            this.messageBus.emit(new EmitEvent('update_my_bet_after_cashout', data));
        }
    }

    ngAfterViewInit() {
        window['initFunction']();
        window['resizeGame'](this.myIdentifier.nativeElement.offsetWidth, this.myIdentifier.nativeElement.offsetHeight - 23);
        this.cd.detectChanges();
    }

    onResized(event: ResizedEvent) {
        if (window['resizeGame']) {
            window['resizeGame'](event.newWidth, event.newHeight - 23);
        }
        this.cd.detectChanges();
    }

    addGamePanel(): void {
        this.gamePanelCount += 1;
    }

    removeGamePanel(): void {
        this.gamePanelCount -= 1;
        this.betRequestFormSecond.autoBet = false;
    }

    betRequest(indexOfPanel: number): void {

        if (this.betRequestFormFirst.betAmount < this.betRestriction.minAmount) {
            this.messageBus.emit(new EmitEvent('errorBetAmountLessThanMinbet', this.betRestriction.minAmount));
            return;
        }

        this.soundService.playSound(SoundNames.button_click);

        const request: BetRequest = {
            index: indexOfPanel,
            bet: (indexOfPanel === 0) ? this.betRequestFormFirst.betAmount : this.betRequestFormSecond.betAmount,
            autoCashOut: (indexOfPanel === 0) ? this.betRequestFormFirst.autoCashOut : this.betRequestFormSecond.autoCashOut,
            cashOutMultiplier: (indexOfPanel === 0) ? this.betRequestFormFirst.cashoutMultiplier : this.betRequestFormSecond.cashoutMultiplier,

            is_free_bet: false,
            free_bet_id: 0,
            free_bet_cash_out_multiplier: 0,
            autoBet: false
        };

        if (indexOfPanel === 0) {
            this.betRequestFormFirst.betButtonActionType = BetButtonActionType.cancel;
        } else {
            this.betRequestFormSecond.betButtonActionType = BetButtonActionType.cancel;
        }

        if (this.gameStatus !== GameStatus.Betting) {
            this.savedBetRequests.push(request);
            return;
        }

        if (indexOfPanel === 0) {
            this.betRequestFormFirst.betActionSended = true;
        } else {
            this.betRequestFormSecond.betActionSended = true;
        }

        this.commService.betRequest(request);
    }

    cashOutRequest(indexOfPanel: number): void {

        // if (indexOfPanel === 0) {
        //     this.betRequestFormFirst.cashoutDisabled = true;
        //     setTimeout(function() { 
        //         this.betRequestFormFirst.cashoutDisabled = false; 
        //     }, 1500);
        // } else {
        //     this.betRequestFormSecond.cashoutDisabled = true;
        //     setTimeout(function() { 
        //         this.betRequestFormSecond.cashoutDisabled = false; 
        //     }, 1500);
        // }

        const request: CashOutAndCancelRequest = {
            index: indexOfPanel,
            actionType: BetActionType.cashout
        };

        this.commService.cashOutAndCancelRequest(request);
    }

    cancelRequest(indexOfPanel: number): void {

        this.soundService.playSound(SoundNames.button_click);

        if (this.gameStatus === GameStatus.MultiplierIncrease) {
            this.savedBetRequests.forEach((betRequest, i) => {
                if (betRequest.index === indexOfPanel) {
                    this.savedBetRequests.splice(i, 1);

                    if (indexOfPanel === 0) {
                        this.betRequestFormFirst.betButtonActionType = BetButtonActionType.betForNextRound;
                    } else {
                        this.betRequestFormSecond.betButtonActionType = BetButtonActionType.betForNextRound;
                    }
                }
            });
            return;
        }

        if (indexOfPanel === 0) {
            this.betRequestFormFirst.betActionSended = true;
        } else {
            this.betRequestFormSecond.betActionSended = true;
        }

        const request: CashOutAndCancelRequest = {
            index: indexOfPanel,
            actionType: BetActionType.cancel
        };

        this.commService.cashOutAndCancelRequest(request);
    }

    startGameStateLoaderAnimation(time: number, totalTime: number): void {
        this.loaderLeftTimeSize = time;
        this.loaderTotalTimeSize = totalTime;
        const millisecondsInSeconds = 1000;
        const i = setInterval(() => {
            this.loaderLeftTimeSize -= this.loaderTimeDelta;

            if (this.loaderLeftTimeSize <= 0) {
                this.loaderLeftTimeSize = 0;
                clearInterval(i);
                this.betRequestFormFirst.betButtonDisabled = true;
                this.betRequestFormSecond.betButtonDisabled = true;
            }
            this.cd.detectChanges();
        }, millisecondsInSeconds * this.loaderTimeDelta)
    }

    updateRoomInfo(roomFullInfo: RoomFullInfo): void {
        this.roomInfo = roomFullInfo.roomInfo;
        this.betRestriction.currencyName = roomFullInfo.roomInfo.currencySymbol;
        this.betRestriction.minAmount = roomFullInfo.roomInfo.minBet;
        this.betRestriction.maxAmount = roomFullInfo.roomInfo.maxBet;

        if (roomFullInfo.roomInfo.selected_bet < roomFullInfo.roomInfo.minBet) {
            this.betRequestFormFirst.betAmount = roomFullInfo.roomInfo.minBet;
            this.betRequestFormSecond.betAmount = roomFullInfo.roomInfo.minBet;
        } else {
            this.betRequestFormFirst.betAmount = roomFullInfo.roomInfo.selected_bet;
            this.betRequestFormSecond.betAmount = roomFullInfo.roomInfo.selected_bet;
        }
    }

    // keyUpBetAmount(event, formIndex: number): void {
    //     this.updateBetAmountValue(event.target.value, formIndex);
    // }

    changeBetAmount(event, formIndex: number): void {
        this.updateBetAmountValue(event.target.value, formIndex);
    }

    keyUpBetAmount(event, formIndex): void {
        let value = event.target.value;
        if (value.includes(',')) { 
            if (formIndex === 0) {
                this.betRequestFormFirst.betAmount = value.replace(',', '');
            } else {
                this.betRequestFormSecond.betAmount = value.replace(',', '');
            }
        }
    }

    updateBetAmountValue(newValue, formIndex: number): void {

        newValue = this.validateInputLengths(newValue, 2);

        // if (newValue < this.betRestriction.minAmount) {
        //     newValue = this.betRestriction.minAmount;
        // }
        if (newValue < 0) {
            newValue = 0;
        }

        if (newValue > this.betRestriction.maxAmount) {
            newValue = this.betRestriction.maxAmount;
        }

        if (formIndex === 0) {
            this.betRequestFormFirst.betAmount = newValue;
        } else {
            this.betRequestFormSecond.betAmount = newValue;
        }
    }

    changeAmount(multiplier: number, formIndex: number) {
        if (formIndex === 0) {
            this.betRequestFormFirst.betAmount = this.betRestriction.getValidBet(multiplier * this.betRequestFormFirst.betAmount);
        } else {
            this.betRequestFormSecond.betAmount = this.betRestriction.getValidBet(multiplier * this.betRequestFormSecond.betAmount);
        }
    }

    setMinMax(isMin: boolean, formIndex: number) {
        if (isMin) {
            if (formIndex === 0) {
                this.betRequestFormFirst.betAmount = this.betRestriction.minAmount;
            } else {
                this.betRequestFormSecond.betAmount = this.betRestriction.minAmount;
            }
            return;
        }

        if (formIndex === 0) {
            this.betRequestFormFirst.betAmount = this.betRestriction.maxAmount;
        } else {
            this.betRequestFormSecond.betAmount = this.betRestriction.maxAmount;
        }
    }

    isMaxAmount(formIndex: number): boolean {
        if (formIndex === 0) {
            return this.betRequestFormFirst.betAmount === this.betRestriction.maxAmount;
        } else {
            return this.betRequestFormSecond.betAmount === this.betRestriction.maxAmount;
        }
    }

    isMinAmount(formIndex: number): boolean {
        if (formIndex === 0) {
            return this.betRequestFormFirst.betAmount === this.betRestriction.minAmount;
        } else {
            return this.betRequestFormSecond.betAmount === this.betRestriction.minAmount;
        }
    }

    toggleAutoBetSlide(formIndex): void {
        if (formIndex === 0) {
            this.betRequestFormFirst.autoBet = !this.betRequestFormFirst.autoBet;
        } else {
            this.betRequestFormSecond.autoBet = !this.betRequestFormSecond.autoBet;
        }
    }

    toggleAutoCashOutSlide(formIndex: number): void {
        if (formIndex === 0) {
            this.betRequestFormFirst.autoCashOut = !this.betRequestFormFirst.autoCashOut;
        } else {
            this.betRequestFormSecond.autoCashOut = !this.betRequestFormSecond.autoCashOut;
        }
    }

    keyUpCashOutMultiplier(event, formIndex: number): void {
        this.updateCashOutMultiplierValue(event.target.value, formIndex);
    }

    changeCashOutMultiplier(event, formIndex: number): void {
        this.updateCashOutMultiplierValue(event.target.value, formIndex);
    }

    updateCashOutMultiplierValue(newValue, formIndex: number): void {

        newValue = this.validateInputLengths(newValue, 3);

        if (newValue < 0.1) {
            newValue = 0.1;
        }

        if (formIndex === 0) {
            this.betRequestFormFirst.cashoutMultiplier = newValue;
        } else {
            this.betRequestFormSecond.cashoutMultiplier = newValue;
        }
    }

    validateInputLengths(value, fixedLength): number {
        if (value) {
            const valueStr = value.toString();

            if (valueStr.indexOf('.') >= 0 && valueStr.split('.')[1].length > fixedLength) {
                const betAmountStrArr = valueStr.split('.');
                value = parseFloat(`${betAmountStrArr[0]}.${betAmountStrArr[1].slice(0, fixedLength)}`);
            }
        }

        return value;
    }


    closeWinPopup(index: number): void {
        this.winPopups.Items.forEach((p, i) => {
            if (index === p.Index) {
                this.winPopups.Items.splice(i, 1);
            }
        });
        if (this.winPopups.Items.length < 2) {
            this.winPopups.TwoItem = false;
        }
    }

    resetBetFromErrorMessageBus(data: ErrorModel): void {
        if (data.index === 0) {
            if (this.gameStatus === GameStatus.MultiplierIncrease) {
                this.betRequestFormFirst.betButtonActionType = BetButtonActionType.betForNextRound;
            } else {
                this.betRequestFormFirst.betButtonActionType = BetButtonActionType.bet;
            }
            this.betRequestFormFirst.autoCashOut = false;
            this.betRequestFormFirst.autoBet = false;
        } else {
            if (this.gameStatus === GameStatus.MultiplierIncrease) {
                this.betRequestFormSecond.betButtonActionType = BetButtonActionType.betForNextRound;
            } else {
                this.betRequestFormSecond.betButtonActionType = BetButtonActionType.bet;
            }
            this.betRequestFormSecond.autoCashOut = false;
            this.betRequestFormSecond.autoBet = false;
        }
    }

    betRequestForFreeBet(freeBet: FreeBet): void {

        this.soundService.playSound(SoundNames.button_click);

        const request: BetRequest = {
            index: 0,
            bet: freeBet.bet_amount,
            autoCashOut: this.betRequestFormFirst.autoCashOut,
            cashOutMultiplier: this.betRequestFormFirst.cashoutMultiplier,

            is_free_bet: true,
            free_bet_id: freeBet.free_bet_id,
            free_bet_cash_out_multiplier: freeBet.min_cashout_multiplier,
            autoBet: false
        };

        if (this.gameStatus === GameStatus.MultiplierIncrease &&
            this.betRequestFormFirst.betButtonActionType === BetButtonActionType.cashout) {
            const error = new ErrorModel();
            error.messageText = 'custom@toUseFreeBetPleaseCancelActiveBet';
            this.messageBus.emit(new EmitEvent('error', error));
            return;
        }

        if (this.savedBetRequests.find(sr => sr.index === 0)) {
            const error = new ErrorModel();
            error.messageText = 'custom@toUseFreeBetPleaseCancelActiveBet';
            this.messageBus.emit(new EmitEvent('error', error));
            return;
        }

        this.betRequestFormFirst.betButtonActionType = BetButtonActionType.cancel;

        if (this.gameStatus !== GameStatus.Betting) {
            this.savedBetRequests.push(request);
            return;
        }

        this.betRequestFormFirst.betActionSended = true;

        // this.betRequestFormFirst.betAmount = freeBet.bet_amount;

        // this.betRequestFormFirst.freeBetMinCashOutMultiplier = freeBet.min_cashout_multiplier;
        this.commService.betRequest(request);
    }


}

