import {Injectable} from '@angular/core';
import {MessageBusService} from "./message-bus.service";
import {IncreaseBet} from "../models/responses/increase-bet";


@Injectable({
    providedIn: 'root'
})
export class GamePlayService {

    isStarted: boolean;

    constructor(private messageBus: MessageBusService) {
        this.messageBus.on('increase_bet', (data: IncreaseBet) => {
            this.progress(data);
        });
    }

    progress(data: any): void {
        if (!this.isStarted) {
            this.isStarted = true;
            let progress = 70;
            if (data.Result > 1.5) {
                progress = 300;
            }
            if (data.Result > 5) {
                progress = 600;
            }
            if (data.Result > 10) {
                progress = 900;
            }
            window['start'](progress);
        }

        if (data.Bust) {
            this.isStarted = false;
            window['stop']();
        }
    }


}
