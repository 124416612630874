import {Injectable} from '@angular/core';
import {ChatBot} from "../models/right-content/chat/bot/chat-bot";
import {Bet} from "../models/left-content/bet";
import {Round} from "../models/right-content/round";
import {EmitEvent} from "../models/emit-event";
import {MessageBusService} from "./message-bus.service";
import {RoomFullInfo} from "../models/game-content/room-full-info";

@Injectable({
    providedIn: 'root',
})
export class ChatBotService {

    chatBotActiveStatus: boolean = true;
    chatBotMinAmount: number = 20;
    chatBotSavedMessages: ChatBot[] = [];

    constructor(private messageBus: MessageBusService) {

        this.messageBus.on('cash_out', (data: Bet) => {
            if (this.chatBotActiveStatus && data.winAmount >= this.chatBotMinAmount) {
                this.addBotMessageInSavedMessages(data);
            }
        });

        this.messageBus.on('round_finish', (data: Round) => {
            const messages: ChatBot[]  = this.chatBotSavedMessages.filter(m => m.round_id === data.roundId);
            if (messages.length>0) {
                messages.forEach(m => {
                    m.round_multiplier = data.multiplier;
                });

                this.messageBus.emit(new EmitEvent('add_bot_messages_in_chat', messages));

                this.chatBotSavedMessages.length = 0;
            }
        });

        this.messageBus.on('pre_join_room', (roomFullInfo: RoomFullInfo) => {
            this.chatBotMinAmount = roomFullInfo.roomInfo.big_win_amount;
        });
    }

    addBotMessageInSavedMessages(data: Bet): void {

        const chatBotMessage: ChatBot = {
            round_multiplier: null,
            avatar_id: data.avatar_id,
            bet: data.bet,
            cash_out: data.cashoutMultiplier,
            win: data.winAmount,
            username: data.username,
            round_id: data.roundId,
            message_text_template_id: Math.floor(Math.random() * 5 + 0) /* Random number 0 - 4 */
        };

        this.chatBotSavedMessages.push(chatBotMessage);
    }



}
