import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from '@ngx-translate/core';
import {MessageBusService} from "../../../core/services/message-bus.service";
import {ChatHistory} from "../../../core/models/responses/chat-history";
import {ChatMessage, ChatMessageType} from "../../../core/models/right-content/chat/chat-message";
import {EmitEvent} from "../../../core/models/emit-event";
import {Player} from "../../../core/models/responses/player";
import {PlayerCountChange} from "../../../core/models/responses/player-count-change";
import {MalihuScrollbarService} from "ngx-malihu-scrollbar";
import {UiService} from "../../../core/services/ui.service";
import {Avatar} from "../../../core/models/responses/avatar";
import {LikeChatMessageRequest} from "../../../core/models/requests/like-chat-message-request";
import {ChatBot} from "../../../core/models/right-content/chat/bot/chat-bot";
import {JackpotHistoryRound} from "../../../core/models/header/jackpots/jackpot-history";

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent implements OnInit, OnDestroy {

    @Input()
    chatVisibility;

    @Output()
    changeChatVisibility = new EventEmitter<boolean>();

    chatVisibilitySubscription: Subscription;

    player: Player;

    chatMessages: ChatMessage[] = [];
    
    chatMessageMaxCount = 100;

    chatText = '';

    playerCount = 1;

    showEmojiPicker = false;

    maxSymbolsNumber = 250;

    // showGiphySearch = false;
    giphySearchTerm = '';
    giphyResults = [];
    giphySearchLoading: boolean = false;
    giphyApiLink = "https://api.giphy.com/v1/gifs/search?api_key=x06djkNpes0JJhGqjtpYiz8xrR5r25e4&limit=10&q=";

    constructor(private cd: ChangeDetectorRef,
                private translateService: TranslateService,
                private messageBus: MessageBusService,
                private mScrollbarService: MalihuScrollbarService,
                private zone: NgZone,
                private http: HttpClient,
                private  uiService: UiService) {
    }

    emojiPickerSearchTranslate(): string {
        return this.translateService.instant("right_content@chat@emoji_picker_search");
    }

    emojiPickerSearchResultsTranslate(): string {
        return this.translateService.instant("right_content@chat@emoji_picker_search_results");
    }


    ngOnInit() {

        this.messageBus.on('connect', (player: Player) => {
            this.player = player;
            this.cd.detectChanges();
        });

        this.messageBus.on('chat_history', (data: ChatHistory) => {
            this.chatMessageMaxCount = data.messageMaxCount;
            this.addChatHistoryMessages(data.messages);
            this.cd.detectChanges();
        });

        this.messageBus.on('player_count_change', (data: PlayerCountChange) => {
            this.playerCount = data.playerCount;
            this.cd.detectChanges();
        });

        this.messageBus.on('chat_message', (chatMessage: ChatMessage) => {
            this.addChatMessage(chatMessage);
            this.cd.detectChanges();
        });

        this.messageBus.on('chat_ban', (data: {Data: number}) => {
            this.removeChatMessagesWithPlayerId(data.Data);
            this.cd.detectChanges();
        });

        this.messageBus.on('hide_message', (data: {Data: string}) => {
            this.removeChatMessagesWithMessageId(data.Data);
            this.cd.detectChanges();
        });

        this.messageBus.on('change_avatar_response', (data: Avatar) => {
            this.changeAvatarResponse(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('message_like', (data: LikeChatMessageRequest) => {
            this.likeChatMessageResponse(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('message_unlike', (data: LikeChatMessageRequest) => {
            this.unLikeChatMessageResponse(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('add_bot_messages_in_chat', (chatBotMessages: ChatBot[]) => {
            this.addBotMessagesInChat(chatBotMessages);
            this.cd.detectChanges();
        });

        this.chatVisibilitySubscription = this.uiService.isChatVisible.subscribe(isChatVisible => {
            if(isChatVisible) {
                setTimeout(() => {
                    this.scrollToBottom();
                    this.cd.detectChanges();
                }, 100);
            }
            this.cd.detectChanges();
        });

        this.messageBus.on('jackpot_history_row', (data: JackpotHistoryRound) => {
            this.addJackpotHistoryRowInChat(data);
            this.cd.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.chatVisibilitySubscription.unsubscribe();
        this.cd.detectChanges();
    }

    addChatMessage = (chatMessage: ChatMessage) => {

        if (chatMessage.playerId === this.player.playerId) {
            chatMessage.isMe = true;
            chatMessage.username = this.player.username;
        }else {
            chatMessage.isMe = false;
        }

        const extraCount = this.chatMessages.length - this.chatMessageMaxCount;

        if (extraCount > 0) {
            this.chatMessages.splice(0, extraCount);
        }

        this.chatMessages.push(chatMessage);

        setTimeout(() => {
            this.scrollToBottom();
            this.cd.detectChanges();
        }, 0);
    };

    scrollToBottom(): void {
        this.mScrollbarService.scrollTo('.scrollableChat', 'last', {});
    }

    toggleChatVisibility(): void {
        this.changeChatVisibility.next();
    }

    addChatHistoryMessages(messages: ChatMessage[]): void {
        for (const message of messages) {
            this.addChatMessage(message);
        }
        setTimeout(() => {
            this.scrollToBottom();
            this.cd.detectChanges();
        }, 2000);
    }

    onKeyDown(event): void {

        if (event.key !== 'Enter') {
            return;
        }

        this.sendChatMessage();
    }

    sendChatMessage(): void {

        if (this.chatText.length === 0) {
            return;
        }

        const message = new ChatMessage();
        message.message = this.chatText;
        message.playerId = this.player.playerId;
        this.messageBus.emit(new EmitEvent('chat_send', message));
        this.chatText = '';
    }

    removeChatMessagesWithPlayerId(playerId: number): void {
        this.chatMessages = this.chatMessages.filter(msg=>msg.playerId!=playerId) ;
    }

    removeChatMessagesWithMessageId(messageId: string): void {
        this.chatMessages = this.chatMessages.filter(msg=>msg.message_id!=messageId) ;
    }

    toggleEmojiPicker() {
        this.showEmojiPicker = !this.showEmojiPicker;
    }

    addEmoji(event) {
        const { chatText } = this;
        const text = `${chatText}${event.emoji.native}`;

        this.chatText = text;
        this.showEmojiPicker = false;
    }

    searchGiphy() {
        this.giphySearchLoading = true;
        const that = this;
        // const giphy = Giphy();
        const searchTerm = this.giphySearchTerm;
        // giphy.search(searchTerm)
        //     .then(res => {
        //         this.giphyResults = res.data;
        //         that.giphySearchLoading = false;
        //         this.cd.detectChanges();
        //     })
        //     .catch(function (error) {
        //         that.giphySearchLoading = false;
        //         console.log(error);
        //     });
        const apiLink = this.giphyApiLink + searchTerm;

        return new Promise(() => {
            this.getGiphyDataRequest(apiLink).subscribe((data: any) => {
                this.giphyResults = data.data;
                that.giphySearchLoading = false;
                this.cd.detectChanges();
            });
        });
    }

    sendGif(title, url) {

        const message = new ChatMessage();
        message.message = '';
        message.playerId = this.player.playerId;

        message.attachment = {
            link: url,
            type: 'image',
        };

        this.messageBus.emit(new EmitEvent('chat_send', message));
        this.chatText = '';
        // this.showGiphySearch = false;
        document.getElementById("chat__gif-button").click();
        this.scrollToBottom();
    }

    toggleGiphySearch() {
        this.giphySearchLoading = true;
        const that = this;
        document.getElementById('chat__giphy-search-input')['value'] = '';
        // const giphy = Giphy();
        const searchTerm = 'wow';
        // giphy.search(searchTerm)
        //     .then(res => {
        //         this.giphyResults = res.data;
        //         that.giphySearchLoading = false;
        //         this.cd.detectChanges();
        //     })
        //     .catch(function (error) {
        //         that.giphySearchLoading = false;
        //         console.log(error);
        //     });

        const apiLink = this.giphyApiLink + searchTerm;

        return new Promise(() => {
            this.getGiphyDataRequest(apiLink).subscribe((data: any) => {
                this.giphyResults = data.data;
                that.giphySearchLoading = false;
                this.cd.detectChanges();
            });
        });
    }

    getGiphyDataRequest(translatesDataUrl: string) {
        return this.http.get(translatesDataUrl);
    }

    changeAvatarResponse(data: Avatar): void {
        this.chatMessages.forEach(message => {
            if (message.playerId === data.player_id) {
                message.avatar_id = data.avatar_id;
            }
        });
    }

    likeChatMessage(messageId: string): void {

        const likeChatMessageRequest: LikeChatMessageRequest = {
            message_id: messageId,
            player_id: 0
        };

        this.messageBus.emit(new EmitEvent('message_like_request', likeChatMessageRequest));
    }

    likeChatMessageResponse(data: LikeChatMessageRequest): void {
        this.chatMessages.forEach(m => {
            if (m.message_id === data.message_id) {
                if (!m.user_likes.find(l => l === data.player_id)) {
                    m.user_likes.push(data.player_id);
                }
            }
        });
    }

    unLikeChatMessageResponse(data: LikeChatMessageRequest): void {
        this.chatMessages.forEach(m => {
            if (m.message_id === data.message_id) {
                m.user_likes.forEach((l, i) => {
                    if (l === data.player_id) {
                        m.user_likes.splice(i, 1);
                    }
                });
            }
        });
    }

    addBotMessagesInChat(chatBotMessages: ChatBot[]): void {
        chatBotMessages.forEach(cbm => {
            const chatMessage: ChatMessage = new ChatMessage();
            chatMessage.type = ChatMessageType.congrats;
            chatMessage.bot_data = cbm;
            this.chatMessages.push(chatMessage);
            this.scrollToBottom();
        });
    }

    addJackpotHistoryRowInChat(round: JackpotHistoryRound): void {
        const chatMessage: ChatMessage = new ChatMessage();
        chatMessage.type = ChatMessageType.jackpot;
        chatMessage.jackpot_data = round;
        this.chatMessages.push(chatMessage);
        this.scrollToBottom();
    }


}
