import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MessageBusService} from "./message-bus.service";
import {TranslateModel} from "../models/translate-model";
import {Languages} from "../models/header/languages";
import {CookiesManagementService} from "./cookies-management.service";
import {CookiesOptionsData} from "../models/cookies-options-data";

@Injectable({
    providedIn: 'root'
})
export class TranslateServiceService {
    public data: any;
    public activeLang = '';

    constructor(public  messageService: MessageBusService, private translatiorService: TranslateService, private cookiesManagementService: CookiesManagementService) {

        this.messageService.on('translations', (data) => {
            this.registerTranslates(data);
            this.defineActiveLanguageWithUrlParameter();

            this.cookiesManagementService.updateActiveLanguageOptionsData(this.activeLang);
        });

        this.messageService.on('lang_change', (activeLang) => {
            this.activeLang = activeLang;
            this.translatiorService.use(activeLang);
            
            document.documentElement.setAttribute("lang", ((activeLang === "az-AZ") ? "az" : ""));
            if (activeLang === "az-AZ") {
                document.documentElement.setAttribute("hidden", "true");
                setTimeout(()=>{
                    document.documentElement.removeAttribute("hidden");
                }, 0);
            }
        });
    }

    registerTranslates(translates: TranslateModel[]): void {
        for (const translate of translates) {
            this.registerTranslate(translate);
        }
    }

    defineActiveLanguageWithUrlParameter(): void {

        const urlLangVariable = this.getUrlVariableByParameter('Lang');

        const language = new Languages().languages.find(l => l.Code === urlLangVariable);

        let activeLanguageCode = 'en-US';

        if (language) {
            activeLanguageCode = language.Code;
        }

        if (!language) {

            const optionsCookieData = this.cookiesManagementService.getCookie('Options');

            if (optionsCookieData !== '') {

                const optionsData = <CookiesOptionsData>JSON.parse(optionsCookieData);

                if (optionsData && optionsData.ActiveLanguage) {
                    activeLanguageCode = optionsData.ActiveLanguage;
                }
            }
        }

        this.activeLang = activeLanguageCode;
        this.translatiorService.use(activeLanguageCode);
        this.translatiorService.setDefaultLang(activeLanguageCode);
        document.documentElement.setAttribute("lang", ((activeLanguageCode === "az-AZ") ? "az" : ""));
    }

    getUrlVariableByParameter(variable: any): string {
        const query = window.location.search.substring(1);
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return '';
    }

    registerTranslate(translate: TranslateModel): void {
        const translateObject = {};
        translateObject[translate.key] = translate.text;
        this.translatiorService.setTranslation(translate.langName, translateObject, true);
    }



}
