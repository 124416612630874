export class JackpotHistory {
    RareWin: number;
    TopRounds: any[];
    MaxCount: number;
    Rounds: JackpotHistoryRound[];
}

export class JackpotHistoryRound {
    round_id:number;
    stop_multiplier: number;
    type: number;
    jackpot_amount: number;
    create_time: string;
    players: JackpotHistoryRoundPlayer[];
    /*Custom*/
    detailViewData: JackpotHistoryRound;
}

export class JackpotHistoryRoundPlayer {
    player_id: number;
    avatar_id: number;
    username: string;
    bet: number;
    win: number;
}
