import {ChatBot} from "./bot/chat-bot";
import {Drop} from "./drop/drop";
import {JackpotHistoryRound} from "../../header/jackpots/jackpot-history";

export class ChatMessage {
    playerId: number;
    avatar_id: number;
    username: string;
    message_id: string;
    message: string;
    isMe: boolean;
    attachment: ChatMessageAttachment;
    user_likes: number[];
    type: ChatMessageType;
    drop_data: Drop;
    /*Custom*/
    bot_data: ChatBot;
    jackpot_data: JackpotHistoryRound;
}

export class ChatMessageAttachment {
    link: string;
    type: string;
}

export  enum  ChatMessageType{
    chat_text,
    gif,
    drop,
    congrats,
    jackpot
}
