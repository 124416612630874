import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mathFloorTwoDigits'
})
export class MathFloorTwoDigitsPipe implements PipeTransform {

  transform(value: number, args?: any): any {
      const valueStr = value.toString();
      let returnedValue = valueStr;

      if (value.toString().indexOf('.') >= 0) {
        const splitedValue = valueStr.split('.');

        if (splitedValue[1].length === 1) {
            returnedValue = `${splitedValue[0]}.${splitedValue[1]}0`;
        } else {
            returnedValue = `${splitedValue[0]}.${splitedValue[1].slice(0, 2)}`;
        }

      } else {
          returnedValue = value.toFixed(2);
      }

      return returnedValue;

     // return (Math.floor(value * 100 ) / 100).toFixed(2);
  }

}
