import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit
} from '@angular/core';
import {Languages} from "../../core/models/header/languages";
import {EmitEvent} from "../../core/models/emit-event";
import {MessageBusService} from "../../core/services/message-bus.service";
import {Player} from "../../core/models/responses/player";
import {GameRules} from "../../core/models/header/game-rules";
import {TranslateServiceService} from "../../core/services/translate-service.service";
import {SoundsService} from "../../core/services/sounds.service";
import {BalanceCheck} from "../../core/models/responses/balance-check";
import {CookiesManagementService} from "../../core/services/cookies-management.service";
import {FreeBets} from "../../core/models/header/free-bets";
import {Bet} from "../../core/models/left-content/bet";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
    @Input() player: Player;
    languages = new Languages();
    activeLang = new Languages().languages[0].Code;
    soundOn: boolean;
    freeBets: FreeBets = new FreeBets();
    hideBalance = false;
    
    constructor(private cd: ChangeDetectorRef, private messageBus: MessageBusService, private translateService: TranslateServiceService,
                private translateServiceService: TranslateServiceService, private soundsService: SoundsService,
                private cookiesManagementService: CookiesManagementService) {
        this.activeLang = this.translateService.activeLang;
        this.languages.languages.forEach(l => {
           l.Active = (l.Code === this.activeLang);
        });
        this.soundOn = this.soundsService.soundOn;
    }

    ngOnInit() {

        this.hideBalanceWithUrlFlagsVariable();

        this.hideLanguagesWithUrlFlagsVariables();

        this.changeAppColorWithUrlFlagsVariables();

        this.messageBus.on('balance_check', (data: BalanceCheck) => {
            this.player.balance = data.balance;
            this.cd.detectChanges();
        });

        this.messageBus.on('game_rules', (data: GameRules) => {
            this.addGameRules(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('free_bets', (data: FreeBets) => {
            this.addFreeBets(data);
            this.cd.detectChanges();
        });

        this.messageBus.on('bet', (bet: Bet) => {
            if (bet.free_bet_id > 0) {
                this.freeBets.Data.forEach((freeBet, i) => {
                    if (bet.free_bet_id === freeBet.free_bet_id) {
                        this.freeBets.Data.splice(i, 1);
                    }
                });
            }
            this.cd.detectChanges();
        });
    }

    hideBalanceWithUrlFlagsVariable(): void {
        const flags = this.getUrlVariableByParameter('flags');
        if (flags && flags.toLocaleLowerCase().includes('hidebalance')) {
            this.hideBalance = true;
            this.cd.detectChanges();
        }
    }

    getUrlVariableByParameter(variable: any): string {
        const query = window.location.search.substring(1);
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            if (pair[0] === variable) return pair[1];
        }
        return '';
    }

    changeLanguage(languageCode: string): void {

        this.activeLang = languageCode;

        this.languages.languages.forEach(l => {
           l.Active = (l.Code === languageCode);
        });

        this.messageBus.emit(new EmitEvent('lang_change', languageCode));

        this.cookiesManagementService.updateActiveLanguageOptionsData(languageCode);
    }

    addGameRules(data: GameRules): void {
        this.translateServiceService.registerTranslates(data.Data);
    }

    soundOnToggle(status: boolean): void {
        this.soundOn = status;
        this.soundsService.updateSoundOn(status);
        this.cookiesManagementService.updateSoundOnOptionsData(status);
    }

    changePlayerAvatar(avatarId: number): void {
        this.player.avatar_id = avatarId;
        const data: {avatar_id: number} = {avatar_id: avatarId};
        this.messageBus.emit(new EmitEvent('change_avatar', data));
    }

    addFreeBets(data: FreeBets): void {
        this.freeBets = data;
    }

    hideLanguagesWithUrlFlagsVariables(): void {
        const flags = this.getUrlVariableByParameter('flags');
        this.languages.languages.forEach(l => {
            if (flags && flags.toLocaleLowerCase().includes(`hidelang_${l.Code.toLocaleLowerCase()}`)) { 
                l.Hide = true;
            }
        });
        this.cd.detectChanges();
    }

    changeAppColorWithUrlFlagsVariables(): void {
        const flags = this.getUrlVariableByParameter('flags');
        if (flags && flags.includes('Color')) {
            const appColor = flags.split('Color_')[1];
            this.changeAppColor(appColor);
            this.cd.detectChanges();
        }
    }

    changeAppColor(appColor: string): void {
        document.querySelector('body').classList.add("color_"+appColor);

        var sheet = document.createElement('style');
        sheet.innerHTML = 
            `.justice-modal__footer-link { color: #${appColor} }`+ 
            `.drop-button { background: #${appColor} }` +
            `.drop-button:active, .drop-button:hover, .drop-button:focus, .drop-button:active:hover, .drop-button:active:focus { background: #${appColor}; opacity: 0.85 }` +
            `.game-loader__bar { background: #${appColor} }` +
            `.header__user-balance span.yellowColor { color: #${appColor} }` +
            `.game-loader__dirigible::before { background-image: url("/assets/img/dirigible-icon_65c8ec.png") }`;

        document.body.appendChild(sheet);
    }

}
