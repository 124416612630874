import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {TranslateModule} from '@ngx-translate/core';
import {MalihuScrollbarModule} from 'ngx-malihu-scrollbar';
import {MomentModule} from "ngx-moment";
import {AngularResizedEventModule} from 'angular-resize-event';
import {PopoverModule} from 'ngx-popover';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {EmojiModule} from '@ctrl/ngx-emoji-mart/ngx-emoji';

import {AppComponent} from './app.component';
import {HeaderComponent} from './content/header/header.component';
import {FooterComponent} from './content/footer/footer.component';
import {LeftContentComponent} from './content/left-content/left-content.component';
import {RightContentComponent} from './content/right-content/right-content.component';
import {GameContentComponent} from './content/game-content/game-content.component';
import {ChatComponent} from './content/right-content/chat/chat.component';
import {CustomDatetimePipe} from "./core/pipes/custom-datetime.pipe";
import { HideUsernamePipe } from './core/pipes/hide-username.pipe';
import {AppInitializerService} from "./core/services/app-initializer.service";
import {HttpClientModule} from "@angular/common/http";
import { JackpotsComponent } from './content/header/jackpots/jackpots.component';
import { RoundsHistoryComponent } from './content/game-content/rounds-history/rounds-history.component';
import { FreeBetsComponent } from './content/header/free-bets/free-bets.component';
import { DropComponent } from './content/right-content/chat/drop/drop.component';
import { ChatMessageLikedPipe } from './core/pipes/chat-message-liked.pipe';
import { MathFloorTwoDigitsPipe } from './core/pipes/math-floor-two-digits.pipe';
import {MathFloorThreeDigitsPipe} from "./core/pipes/math-floor-three-digits.pipe";

export function init_app(appInitializerService: AppInitializerService) {
    return () => appInitializerService.initialize();
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        LeftContentComponent,
        RightContentComponent,
        GameContentComponent,
        ChatComponent,
        CustomDatetimePipe,
        HideUsernamePipe,
        JackpotsComponent,
        RoundsHistoryComponent,
        FreeBetsComponent,
        DropComponent,
        ChatMessageLikedPipe,
        MathFloorTwoDigitsPipe,
        MathFloorThreeDigitsPipe
    ],
    imports: [
        BrowserModule,
        FormsModule,
        MomentModule,
        HttpClientModule,
        AngularResizedEventModule,
        PopoverModule,
        PickerModule,
        EmojiModule,
        TranslateModule.forRoot(),
        MalihuScrollbarModule.forRoot(),
    ],
    providers: [
        {provide: APP_INITIALIZER, useFactory: init_app, deps: [AppInitializerService], multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}


