import {Injectable} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {filter, map} from 'rxjs/operators';
import {EmitEvent} from "../models/emit-event";

@Injectable({
    providedIn: 'root'
})
export class MessageBusService {

    private subject = new Subject<any>();

    constructor() {
    }

    on(event: any, action: any): Subscription {
        return this.subject.pipe(
            filter((ev: EmitEvent) => ev.command === event),
            map((ev: EmitEvent) => ev.data)
        ).subscribe(action);
    }

    emit(event: EmitEvent): void {
        this.subject.next(event);
    }


}
