import {LanguagesEnum} from "./languages.enum";

export class Languages {
    languages: { Id: number, Code: string, Hide: boolean, Active: boolean }[] = [
        {Id: LanguagesEnum.English, Code: 'en-US', Hide: false, Active: true},
        {Id: LanguagesEnum.Turkish, Code: 'tr-TR', Hide: false, Active: false},
        {Id: LanguagesEnum.Georgian, Code: 'ka-GE', Hide: false, Active: false},
        {Id: LanguagesEnum.Russian, Code: 'ru-RU', Hide: false, Active: false},
        {Id: LanguagesEnum.Persian, Code: 'fa-IR', Hide: false, Active: false},
        {Id: LanguagesEnum.Italian, Code: 'it-IT', Hide: false, Active: false},
        {Id: LanguagesEnum.Ukrainian, Code: 'uk-UA', Hide: false, Active: false},
        {Id: LanguagesEnum.Portuguese, Code: 'pt-PT', Hide: false, Active: false},
        {Id: LanguagesEnum.Spanish, Code: 'es-ES', Hide: false, Active: false},
        {Id: LanguagesEnum.Korean, Code: 'ko-KR', Hide: false, Active: false},
        {Id: LanguagesEnum.French, Code: 'fr-FR', Hide: false, Active: false},
        {Id: LanguagesEnum.Armenian, Code: 'hy-AM', Hide: false, Active: false},
        {Id: LanguagesEnum.Arabic, Code: 'ar-AE', Hide: false, Active: false},
        {Id: LanguagesEnum.Chinese, Code: 'zh-CN', Hide: false, Active: false},
        {Id: LanguagesEnum.Azeri, Code: 'az-AZ', Hide: false, Active: false},
        {Id: LanguagesEnum.Brazil, Code: 'pt-BR', Hide: false, Active: false},
        {Id: LanguagesEnum.Hindi, Code: 'hi-IN', Hide: false, Active: false}
    ]
}
