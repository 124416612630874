import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UiService {

    isMobile = new BehaviorSubject<boolean>(false);
    isChatVisible = new Subject<boolean>();

    constructor() {

    }
}
