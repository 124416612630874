import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ResizedEvent} from "angular-resize-event";
import {UiService} from "../../core/services/ui.service";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {

    @Output()
    ChangeMobileMenu = new EventEmitter();

    activeMobileMenuItem: number = 1;
    isChatVisible: boolean = false;

    getCurrentYear: number = new Date().getFullYear();

    constructor(private cd: ChangeDetectorRef, private uiService: UiService) {
    }

    ngOnInit() {
    }

    changeMobileMenu(itemId: number): void {
        this.activeMobileMenuItem = itemId;
        this.ChangeMobileMenu.emit(this.activeMobileMenuItem);

        if(itemId == 4) {
            this.uiService.isChatVisible.next(true);
            this.isChatVisible = true;
        } else if(this.isChatVisible) {
            this.uiService.isChatVisible.next(false);
            this.isChatVisible = false;
        }
    }

    onResized(event: ResizedEvent) {
        this.changeMobileMenu(1);
    }



}
