import {Injectable} from '@angular/core';
import {CookiesOptionsData} from "../models/cookies-options-data";

@Injectable({
    providedIn: 'root'
})
export class CookiesManagementService {

    constructor () {
        this.createOptionsDataCookieIfNotExist();
    }

    createCookie(name, value, days) {
        let date, expires;
        if (days) {
            date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            expires = "; expires="+ date.toGMTString();
        } else {
            expires = "";
        }
        document.cookie = name+"="+value+expires+";path=/;SameSite=None;secure";
    }

    getCookie(cname: string) {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    createOptionsDataCookieIfNotExist(): void {
        if (this.getCookie('Options') === '') {
            const optionsData = new CookiesOptionsData();
            this.createCookie('Options', JSON.stringify(optionsData), 3);
        }
    }

    updateSoundOnOptionsData(status: boolean): void {

        const optionsCookieData = this.getCookie('Options');

        if (optionsCookieData !== '') {
            const optionsData = <CookiesOptionsData>JSON.parse(optionsCookieData);
            optionsData.SoundOn = status;
            this.createCookie('Options', JSON.stringify(optionsData), 3);
        }
    }

    updateChatVisibilityOptionsData(status: boolean): void {

        const optionsCookieData = this.getCookie('Options');

        if (optionsCookieData !== '') {
            const optionsData = <CookiesOptionsData>JSON.parse(optionsCookieData);
            optionsData.ChatVisible = status;
            this.createCookie('Options', JSON.stringify(optionsData), 3);
        }
    }

    updateActiveLanguageOptionsData(activeLanguage: string): void {

        const optionsCookieData = this.getCookie('Options');

        if (optionsCookieData !== '') {
            const optionsData = <CookiesOptionsData>JSON.parse(optionsCookieData);
            optionsData.ActiveLanguage = activeLanguage;
            this.createCookie('Options', JSON.stringify(optionsData), 3);
        }
    }



}
