import { Injectable } from '@angular/core';
import { SoundModel } from '../models/sound-model';
import { SoundNames } from '../models/sound-names.enum';
import { UiService } from './ui.service';
import {CookiesManagementService} from "./cookies-management.service";
import {CookiesOptionsData} from "../models/cookies-options-data";

@Injectable({
    providedIn: 'root'
})
export class SoundsService {

    soundOn = true;

    audios: SoundModel[] = [];

    constructor(private uiService: UiService, private cookiesManagementService: CookiesManagementService) {

        this.loadSounds();

        this.playMainSound();

        this.soundOffIfMobile();

        this.soundOffWithCookieOptionsData();
    }

    playMainSound(): void {
        this.playSound(SoundNames.main_sound);
    }

    loadSounds(): void {

        const mainSound: SoundModel = { Name: SoundNames.main_sound, AudioObject: new Audio() };
        mainSound.AudioObject.src = '../assets/audio/Dirijible.mp3';
        mainSound.AudioObject.loop = true;
        mainSound.AudioObject.load();
        this.audios.push(mainSound);

        const buttonClickSound: SoundModel = { Name: SoundNames.button_click, AudioObject: new Audio() };
        buttonClickSound.AudioObject.src = '../assets/audio/ButtonClick.mp3';
        buttonClickSound.AudioObject.load();
        this.audios.push(buttonClickSound);

        const explosionSound: SoundModel = { Name: SoundNames.explosion, AudioObject: new Audio() };
        explosionSound.AudioObject.src = '../assets/audio/Explosion.mp3';
        explosionSound.AudioObject.load();
        this.audios.push(explosionSound);

        const startSound: SoundModel = { Name: SoundNames.start, AudioObject: new Audio() };
        startSound.AudioObject.src = '../assets/audio/Start.mp3';
        startSound.AudioObject.load();
        this.audios.push(startSound);

        const winSound: SoundModel = { Name: SoundNames.win, AudioObject: new Audio() };
        winSound.AudioObject.src = '../assets/audio/Win.mp3';
        winSound.AudioObject.load();
        this.audios.push(winSound);

        const collectSound: SoundModel = { Name: SoundNames.collect, AudioObject: new Audio() };
        collectSound.AudioObject.src = '../assets/audio/Collect3.mp3';
        collectSound.AudioObject.load();
        this.audios.push(collectSound);

        const o2Sound: SoundModel = { Name: SoundNames.o2_win, AudioObject: new Audio() };
        o2Sound.AudioObject.src = '../assets/audio/O2Win.mp3';
        o2Sound.AudioObject.load();
        this.audios.push(o2Sound);

        const heSound: SoundModel = { Name: SoundNames.he_win, AudioObject: new Audio() };
        heSound.AudioObject.src = '../assets/audio/HeWin.mp3';
        heSound.AudioObject.load();
        this.audios.push(heSound);
    }

    playSound(soundNameEnumValue: number): void {

        const mainSound = this.audios.find(s => s.Name === soundNameEnumValue);

        if (mainSound !== undefined && mainSound.AudioObject.paused) {
            mainSound.AudioObject.play();
        }
    }

    updateSoundOn(status: boolean): void {
        this.soundOn = status;

        this.audios.forEach(a => {
            a.AudioObject.muted = !status;
        });
    }

    soundOffIfMobile(): void {

        const platform = this.getUrlVariableByParameter('platform');

        if (platform === 'mobile') {
            this.updateSoundOn(false);
            this.uiService.isMobile.next(true);
        }
    }

    getUrlVariableByParameter(variable: any): string {
        const query = window.location.search.substring(1);
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return '';
    }

    soundOffWithCookieOptionsData(): void {
        const optionsCookieData = this.cookiesManagementService.getCookie('Options');
        if (optionsCookieData !== '') {
            const optionsData = <CookiesOptionsData>JSON.parse(optionsCookieData);
            if (!optionsData.SoundOn) {
                this.updateSoundOn(optionsData.SoundOn);
            }
        }
    }



}
