import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MessageBusService} from "./core/services/message-bus.service";
import {CommunicationManagerService} from "./core/services/communication-manager.service";
import {Player} from "./core/models/responses/player";
import {BalanceCheck} from "./core/models/responses/balance-check";
import {GamePlayService} from "./core/services/game-play.service";
import {AppInitializerService} from "./core/services/app-initializer.service";
import {ErrorModel} from "./core/models/responses/error-model";
import {SoundsService} from "./core/services/sounds.service";
import {UiService} from "./core/services/ui.service";
import {SoundNames} from "./core/models/sound-names.enum";
import {EmitEvent} from "./core/models/emit-event";
import {PlayerBetHistory} from "./core/models/right-content/player-bet-history";
import {Avatar} from "./core/models/responses/avatar";

declare var $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
    loaderVisible = true;
    player: Player = new Player();
    error: ErrorModel;
    fatalError: ErrorModel;
    activeMobileMenuItem = 1;
    isMobile = false;
    minBetAmount = 0;

    constructor(private cd: ChangeDetectorRef,
                private messageBus: MessageBusService,
                private appInitializerService: AppInitializerService,
                private communicationManagerService: CommunicationManagerService,
                private gamePlay: GamePlayService,
                private uiService: UiService,
                private soundsService: SoundsService) {
    }

    ngOnInit() {

        this.messageBus.on('connect', (player: Player) => {
            this.player = player;
            this.cd.detectChanges();
        });

        this.messageBus.on('avatar', (data: Avatar) => {
            this.player.avatar_id = data.avatar_id;
            this.cd.detectChanges();
        });

        this.messageBus.on('balance_check', (data: BalanceCheck) => {
            this.player.balance = data.balance;
            this.cd.detectChanges();
        });

        this.messageBus.on('error', (error: ErrorModel) => {
            this.addErrorFromMessageBus(error);
            this.cd.detectChanges();
        });

        this.messageBus.on('bet_error', (error: any) => {
            this.addBetErrorFromMessageBus(error);
            this.cd.detectChanges();
        });

        this.messageBus.on('errorBetAmountLessThanMinbet', (minBet: number) => {
            this.addBetAmountLessThanMinbetErrorFromMessageBus(minBet);
            this.cd.detectChanges();
        });

        this.messageBus.on('player_bet_history', (data: PlayerBetHistory) => {
            this.removeLoader();
            this.cd.detectChanges();
        });

        this.uiService.isMobile.subscribe(isMobile => {
            this.isMobile = isMobile;
            if (this.isMobile) {
                setTimeout(() => {
                    // $("html, body").animate({scrollTop: $(document).height()-30}, 1000);
                    // $("html, body").animate({scrollTop: $(".buttons-panel__button-cont").offset().top - window.outerHeight + 40}, 1000);
                    $('html, body').animate({scrollTop: 65}, 1000);
                }, 2500);
            }
            this.cd.detectChanges();
        });
    }

    clickMainContainer(): void {
        this.soundsService.playSound(SoundNames.main_sound);
    }

    addErrorFromMessageBus(error: ErrorModel): void {
        if (error.fatal) {
            this.fatalError = error;
        } else {
            this.error = error;
            $('#error-modal').modal();
            if (error.errorId === 409) {
                this.communicationManagerService.clearCheckBalanceInterval();
            }
        }
    }

    addBetErrorFromMessageBus(error: ErrorModel): void {
        this.error = error;
        $('#error-modal').modal();
        setTimeout(() => {
            $('#error-modal').modal('hide');
            this.cd.detectChanges();
        }, 3000);

        if (error.resetBet) {
            this.messageBus.emit(new EmitEvent('reset_bet_from_error', error));
        }
    }

    addBetAmountLessThanMinbetErrorFromMessageBus(minBet: number) {
        this.minBetAmount = minBet;
        $('#error-modal-bet-amount-less-than-minimum-bet').modal();
        setTimeout(() => {
            $('#error-modal-bet-amount-less-than-minimum-bet').modal('hide');
            this.cd.detectChanges();
        }, 3000);
    }

    changeMobileMenu(menuItemId: number): void {
        this.activeMobileMenuItem = menuItemId;
        this.cd.detectChanges();
    }

    removeLoader() {
        const self = this;
        setTimeout(() => {
            self.loaderVisible = false;
            this.cd.detectChanges();
        }, 500);
    }



}
