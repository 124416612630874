export class BetRestriction {
  minAmount = 1;
  maxAmount = 2;
  currencyName = '';

  getValidBet(amount: number): number {
    if (amount < this.minAmount) {
      amount = this.minAmount;
    } else if (amount > this.maxAmount) {
      amount = this.maxAmount;
    }
    return amount;
  }


}
