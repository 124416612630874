export class Player {
  connection_status: number; // TODO
  playerId: number;
  username = '';
  merchantId: number;
  number;
  balance = 0;
  currency: string;
  currencyId: number;
  avatar_id: number;
}
