import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Player} from "../../core/models/responses/player";
import {MessageBusService} from "../../core/services/message-bus.service";
import {Round} from "../../core/models/right-content/round";
import {Rounds} from "../../core/models/responses/rounds";
import {RoundsTabs} from "../../core/models/right-content/rounds-tabs";
import {CookiesOptionsData} from "../../core/models/cookies-options-data";
import {CookiesManagementService} from "../../core/services/cookies-management.service";

declare var $: any;

@Component({
    selector: 'app-right-content',
    templateUrl: './right-content.component.html',
    styleUrls: ['./right-content.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightContentComponent implements OnInit {

    @Input()
    player: Player;

    @Input()
    activeMobileMenuItem: number;

    roundsTabs = new RoundsTabs();

    rounds: Rounds;

    activeRound: Round;

    chatVisibility = true;

    constructor(private cd: ChangeDetectorRef, private messageBus: MessageBusService, private cookiesManagementService: CookiesManagementService) {
        this.chatVisibleOffWithCookieOptionsData();
    }

    ngOnInit() {
        // this.messageBus.on('rounds', (rounds: Rounds) => {
        //     this.rounds = rounds;
        //     this.cd.detectChanges();
        // });
        //
        // this.messageBus.on('round_finish', (round: Round) => {
        //     this.rounds.Rounds.forEach(r => {
        //         if (r.roundId === round.roundId) {
        //             r.multiplier = round.multiplier;
        //             r.key = round.key;
        //             r.result = round.result;
        //             r.isFinished = round.isFinished;
        //             r.jackpotType = round.jackpotType;
        //         }
        //     });
        //     if (round.isTop) {
        //         this.rounds.TopRounds.push(round);
        //     }
        //     this.cd.detectChanges();
        // });
        //
        // this.messageBus.on('round_create', (round: Round) => {
        //     this.rounds.Rounds.push(round);
        //     this.cd.detectChanges();
        // });
        //
        // this.messageBus.on('openRoundDetailModel', (roundId: number) => {
        //     this.openRoundDetailModel(roundId);
        //     this.cd.detectChanges();
        // });
    }

    changeChatVisibility(): void {
        this.chatVisibility = !this.chatVisibility;
        this.cookiesManagementService.updateChatVisibilityOptionsData(this.chatVisibility);
        this.cd.detectChanges();
    }

    changeRoundsTabs(tabId: number): void {
        this.roundsTabs.tabs.forEach(tab => {
            tab.Active = (tabId === tab.Id);
        });
    }

    openRoundDetailModel(roundId: number): void {
        this.rounds.Rounds.forEach(r => {
            if (r.roundId === roundId) {
                this.activeRound = r;
            }
        });
        $('#justice-modal').modal();
    }

    openRoundDetailModelForTopRounds(roundId: number): void {
        this.rounds.TopRounds.forEach(r => {
            if (r.roundId === roundId) {
                this.activeRound = r;
            }
        });
        $('#justice-modal').modal();
    }

    chatVisibleOffWithCookieOptionsData(): void {

        const optionsCookieData = this.cookiesManagementService.getCookie('Options');

        if (optionsCookieData!=='') {
            const optionsData = <CookiesOptionsData>JSON.parse(optionsCookieData);

            if (!optionsData.ChatVisible) {
                this.chatVisibility = false;
            }
        }
    }

}
