export class DropForm {
    min_amount: number = 0.5;
    max_amount: number = 90;
    min_player_count: number = 1;
    max_player_count: number = 90;

    amount: number = 1;
    players_count: number = 3;

    requestSuccessfullySended: boolean = false;
    requestDisabled: boolean = false;
}
