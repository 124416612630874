import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'chatMessageLiked',
    pure: false
})
export class ChatMessageLikedPipe implements PipeTransform {

    transform(userLikes: any[], playerId?: number): any {
        return userLikes.find(l => l === playerId);
    }

}
