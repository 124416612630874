import {Injectable} from '@angular/core';
import {EmitEvent} from "../models/emit-event";
import {MessageBusService} from "./message-bus.service";

@Injectable({
    providedIn: 'root'
})
export class RightContentService {

    constructor(private messageBus: MessageBusService) {
    }

    openRoundDetailModel(roundId: number): void {
        this.messageBus.emit(new EmitEvent('openRoundDetailModel', roundId));
    }

}
