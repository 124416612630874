export class RoomInfo {
    minBet: number;
    maxBet: number;
    selected_bet: number;
    rakePercent: number;
    currencySymbol: string;
    isPersistent: false;
    isProtected: false;
    leaderBoardMinAmount: number;
    maxWin: number;
    merchantId: number;
    roomId: number;
    roomState: number;
    roomname: string;
    big_win_multiplier: number;
    big_win_amount: number;
}
