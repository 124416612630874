import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {TranslateModel} from "../models/translate-model";
import {MessageBusService} from "./message-bus.service";
import {EmitEvent} from "../models/emit-event";
import {TranslateServiceService} from "./translate-service.service";
import {ChatBotService} from "./chat-bot.service";
import {CookiesManagementService} from "./cookies-management.service";
import { UiConfigData } from '../models/ui-config-data';

@Injectable({
    providedIn: 'root'
})
export class AppInitializerService {

    private getTranslatesDataUrl = environment.translatesUrl;
    private getUiConfigDataUrl = environment.uiConfigUrl;

    constructor(private http: HttpClient, private messageBus: MessageBusService,
                private cookiesManagementService: CookiesManagementService,
                private translateServiceService: TranslateServiceService,
                private chatBot: ChatBotService) {
    }

    initialize(): Promise<any> {

        return new Promise((resolve, reject) => {

            this.getTranslates(this.getTranslatesDataUrl).subscribe((data: TranslateModel[]) => {

                this.messageBus.emit(new EmitEvent('translations', data));

                this.getGameUiConfig(this.getUiConfigDataUrl, this.getMerchantIdFromUrl()).subscribe((data: UiConfigData) => {

                    if (data.configs.ImageUrl) {
                        this.updateAppImageUsingUiConfigData(data.configs.ImageUrl);
                    }

                    if (data.configs.StyleSeetUrl) {

                        $.get(data.configs.StyleSeetUrl, cssContent => {

                            try {

                                this.updateAppCssUsingUiConfigData(cssContent.replace(/^\s+|\s+$/gm,''));

                            } catch(e) {
                                console.log('Error updateAppCssUsingUiConfigData');
                            }
                            resolve();
                        });
                    } else {
                        resolve();
                    }
                });
            });
        });
    }

    getTranslates(translatesDataUrl: string) {
        return this.http.post(translatesDataUrl, null);
    }

    getGameUiConfig(uiConfigDataUrl: string, merchantId: string) {
        return this.http.post(uiConfigDataUrl, {"MerchantId": merchantId});
    }

    getMerchantIdFromUrl(): string {
        const query = window.location.search.substring(1);
        const vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            if (pair[0] === 'MerchantId') return pair[1];
        }
        return '';
    }

    updateAppImageUsingUiConfigData(imageUrl: string): void {
        var sheet = document.createElement('style');
        sheet.innerHTML = `.game-loader__dirigible::before { background-image: url("${imageUrl}") }`;
        document.body.appendChild(sheet);
        document.body.setAttribute('data-configs-image-url', imageUrl);
    }

    updateAppCssUsingUiConfigData(cssContent: string): void {
        var styleSheet = document.createElement("style");
        styleSheet.innerText = cssContent;
        document.head.appendChild(styleSheet);
    }

}
