export class WinPopups {
    TwoItem: boolean;
    Items: WinPopup[] = [];
}

export class WinPopup {
    Active: boolean;
    Multiplier: number;
    Win: number;
    Index: number;
}
